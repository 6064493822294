import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const TWMITG = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me cook recipes from Vivian Howard's This Will Make It Taste Good cookbook, a series of flavor-enhancing items you can keep on hand to add to dishes to amp them up with less work.";

  return (
    <Layout>
      <Seo title="This Will Make It Taste Good" meta={metaData} image={image} description={description}/>
      <h1>This Will Make It Taste Good</h1>
      <h2>A New Path to SIMPLE Cooking</h2>
      <p>
        <a href="https://www.vivianhoward.com/">Vivian Howard</a> is a chef, restauranteur, 
        author, and native North Carolinian. <i>This Will Make It Taste Good</i>, her second 
        cookbook, is a different kind of cookbook than <Link to="/drr">Deep Run Roots</Link>.
        It contains recipes for flavor-enhancing ingredients you can keep on hand in your 
        refrigerator, freezer, or pantry that will help you, as she says, "make basic food taste 
        fantastic." She calls these "flavor heroes", and then includes both simple suggestions 
        and more intricate recipes for using them.
      </p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/this will make it taste good/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default TWMITG;
