import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const PostList = (props) => {

  const data = props.data;

  if (data == null || typeof data === "undefined") {
    return null;
  }

  // shortcut "deep" copy because sometimes the data was 
  // sorted alphabetically in other components, where we
  // don't want that
  const sortedData = JSON.parse(JSON.stringify(data));

  sortedData.sort((a,b) => {
    if (a.fields.slug > b.fields.slug) return 1;
    else return -1;
  });
  
  return (
    <div className="posts-container">
      {sortedData.map((node, index) => (
        <div className="post-item" key={index}>
          <Link to={node.fields.slug}>
            <GatsbyImage image={getImage(node.fields.thumbnail)} className="post-thumbnail" alt={node.fields.title + ' from ' + node.frontmatter.source}/> 
          </Link>
          <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          <div>{node.frontmatter.date}</div>
        </div>
      ))}
    </div>
  );
}

PostList.propTypes = {
  data: PropTypes.array,
}

PostList.defaultProps = {
  data: [],
}

export default PostList